import * as React from "react";
import styled from "styled-components";
import { ContentBlockCalendarBlockType, EventCalendarEventType } from "../../types/Types";
import { fonts } from "../../styles/variables";
import Container from "../Container";
import ContentBlockCalendarBlockEvent from "./ContentBlockCalendarBlockEvent";

const StyledContentBlockCalendarBlock = styled.div`
    margin: 35px 0 15px 0;
`;
const StyledTitle = styled.h1`
    font: ${fonts.headline};
    text-align: center;
    text-transform: uppercase;
`
const StyledTextContainer = styled.div`
    * {
    font: ${fonts.text};
    }

    p {
    font: ${fonts.text};
    }

    strong {
    font-weight: 700;
    }

    ul, ol, li {
    font: ${fonts.text};
    }

    ul {
    li {
        list-style-type: "– ";
    }
    }

    a {
    color: inherit;
    }
`

const ContentBlockCalendarBlock: React.FC<ContentBlockCalendarBlockType> = ({ title, calendarDescription, calendarEvents, language }) => {
    return (
        <StyledContentBlockCalendarBlock>
            <Container>
                <StyledTitle>{title}</StyledTitle>
                <StyledTextContainer dangerouslySetInnerHTML={{ __html: calendarDescription }} />
            </Container>
            {calendarEvents && calendarEvents.length > 0 && calendarEvents.map((calendarEvent) => {
                return (
                    <ContentBlockCalendarBlockEvent
                        {...(calendarEvent as EventCalendarEventType)}
                        language={language}
                        key={calendarEvent.id}
                    />
                )

            })}
        </StyledContentBlockCalendarBlock>
    )
}
export default ContentBlockCalendarBlock;