import * as React from "react";
import styled from "styled-components";
import Imgix from "react-imgix";
import { ImageImgixType } from "../types/Types";

const StyledImageImgix = styled(Imgix)`
width: 100% !important;
height: auto;
`;

const ImageWrapper = styled.div``;

const ImageImgix: React.FC<ImageImgixType> = ({
  id,
  src,
  sizes,
  imgixParams,
  htmlAttributes,
  className,
  width,
  height
}) => {
  const stylesheet = `.image-wrapper-${id} { aspect-ratio: ${width} / ${height}; }`

  return (
    <>
      <style>
        {stylesheet}
      </style>
      <ImageWrapper 
        className={`image-wrapper-${id}`}
      >
      <StyledImageImgix
        src={src}
        sizes={sizes}
        htmlAttributes={htmlAttributes}
        imgixParams={imgixParams}
        className={`${className}`}
      />
      </ImageWrapper>
    </>
  );
};

export default ImageImgix;
