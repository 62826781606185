import * as React from "react";
import styled from "styled-components";
import { ContentBlockCategoriesTeaserBlockType, ContentBlockCatBlockRoomsType, ContentBlockImageBlockType, ContentBlockTextBlockType, ContentBlockTitleBlockType, ContentBlocksType, ContentBlockCalendarBlockType, ContentBlockMenuCardBlockType, ContentBlockTwoColumnsBlockType, ContentBlockNewsletterBlockType } from "../types/Types"
import ContentBlockTitleBlock from "./ContentBlocks/ContentBlockTitleBlock";
import ContentBlockTextBlock from "./ContentBlocks/ContentBlockTextBlock";
import ContentBlockImageBlock from "./ContentBlocks/ContentBlockImageBlock";
import ContentBlockMenuCardBlock from "./ContentBlocks/ContentBlockMenuCardBlock";
import ContentBlockCalendarBlock from "./ContentBlocks/ContentBlockCalendarBlock";
import ContentBlockCatBlockRooms from "./ContentBlocks/ContentBlockCatBlockRooms";
import ContentBlockCategoriesTeaserBlock from "./ContentBlocks/ContentBlockCategoriesTeaserBlock";
import ContentBlockTwoColumnsBlock from "./ContentBlocks/ContentBlockTwoColumnsBlock";
import ContentBlockNewsletterBlock from "./ContentBlocks/ContentBlockNewsletterBlock";

const StyledContentBlocks = styled.div``;

const ContentBlocks: React.FC<ContentBlocksType> = ({ contentBlocks, language, overviewButtonText, overviewButtonLink }) => {
    return (
        <StyledContentBlocks>
            {contentBlocks && contentBlocks.length > 0 && contentBlocks.map((contentBlock) => {
                switch (contentBlock.typeHandle) {
                    case "contentBlockImageBlock":
                        return (
                            <ContentBlockImageBlock
                                {...(contentBlock as ContentBlockImageBlockType)}
                                key={contentBlock.id}
                            />
                        );
                    case "contentBlockMenuCardBlock":
                        return (
                            <ContentBlockMenuCardBlock
                                {...(contentBlock as ContentBlockMenuCardBlockType)}
                                key={contentBlock.id}
                            />
                        );
                    case "contentBlockTextBlock":
                        return (
                            <ContentBlockTextBlock
                                {...(contentBlock as ContentBlockTextBlockType)}
                                key={contentBlock.id}
                            />
                        );
                    case "contentBlockTitleBlock":
                        return (
                            <ContentBlockTitleBlock
                                {...(contentBlock as ContentBlockTitleBlockType)}
                                key={contentBlock.id}
                            />
                        );
                    case "contentBlockCalendarBlock":
                        return (
                            <ContentBlockCalendarBlock
                                {...(contentBlock as ContentBlockCalendarBlockType)}
                                language={language}
                                key={contentBlock.id}
                            />
                        );
                    case "contentBlockCatBlockRooms":
                        return (
                            <ContentBlockCatBlockRooms
                                {...(contentBlock as ContentBlockCatBlockRoomsType)}
                                language={language}
                                key={contentBlock.id}
                                overviewButtonLink={overviewButtonLink}
                                overviewButtonText={overviewButtonText}
                            />
                        );
                    case "contentBlockCategoriesTeaserBlock":
                        return (
                            <ContentBlockCategoriesTeaserBlock
                                {...(contentBlock as ContentBlockCategoriesTeaserBlockType)}
                                key={contentBlock.id}
                            />
                        );
                    case "contentBlockTwoColumnsBlock":
                        return (
                            <ContentBlockTwoColumnsBlock
                                {...(contentBlock as ContentBlockTwoColumnsBlockType)}
                                key={contentBlock.id}
                            />
                        );
                    case "contentBlockNewsletterBlock":
                        return (
                            <ContentBlockNewsletterBlock
                                {...(contentBlock as ContentBlockNewsletterBlockType)}
                                key={contentBlock.id}
                            />
                        );
                    default:
                        "";
                }
            })}
        </StyledContentBlocks>
    )
};

export default ContentBlocks;
