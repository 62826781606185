import * as React from "react";
import styled from "styled-components";
import { breakpoints, colors, fonts } from "../../styles/variables";
import { EventType } from "../../types/Types";
import Container from "../Container";
import { Link } from "gatsby";
import moment from "moment-timezone";
import "moment/locale/de-ch";
import ImageImgix from "../ImageImgix";

const StyledEventCalendarEvent = styled.div`
    margin: 35px 0 15px 0;
`;
const StyledEventCalendarEventInner = styled.div`
    display: flex;
    flex-wrap: wrap;
    column-gap: 20px;
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-top: solid 2px ${colors.black};
    position: relative;
    background-color: white;
`
const StyledImageColumn = styled.div`
    flex: 1 1 calc(35% - 10px);
    line-height: 0;
`
const StyledTextColumn = styled.div`
    flex: 1 1 calc(65% - 30px);
    padding-top: 15px;
    padding-right: 20px;
`
const ImageContainer = styled.div`
    min-width: 250px;
    width: 100%;
`
const StyledEventDate = styled.p`
    margin: 0;
`
const StyledTitle = styled.h1`
    font: ${fonts.supertitle};
    font-size: 40px;
    text-transform: uppercase;
    margin-top: 0;
    margin-bottom: 0.5rem;
    hyphens: auto;

    @media screen and (min-width: ${breakpoints.sm}px) {
        font-size: 60px;
    }
    `
const StyledDescription = styled.p`
    font: ${fonts.text};

    a {
        color: black;
    }
`
const StyledButton = styled.div`
    position: relative;
    text-align: right;
    z-index: 2;

    a {
        border: solid 1px black;
        padding: 0.5em 1em;
        color: black;
        text-decoration: none;
        display: inline-block;
    }
`
const StyledLink = styled(Link)`
    padding-left: 10px;

    &:after {
        content: "";
        position: absolute;
        inset: 0 0 0 0;
        display: block;
        z-index: 1;
    }
`

const EventCalendar: React.FC<EventType> = ({
    title,
    eventdateEventBegin,
    ticketlink,
    overviewDescription,
    overviewImage,
    uri,
    language
}) => {

    const imageParams = {
        sizes:
            "(max-width: 576px) 320px 100vh, (max-width: 768px) 320px, (max-width: 992px) 320px, (max-width: 1200px) 320px", // todo
        params: {
            q: 50,
            ar: 1,
            fit: "crop"
        },
    };

    const eventDatum = moment(eventdateEventBegin).isValid() ? moment(eventdateEventBegin).locale(`${language}`).format("DD.MM.YYYY") : "";

    return (
        <StyledEventCalendarEvent>
            <Container>

                <StyledEventCalendarEventInner>
                    {overviewImage && overviewImage.length > 0 &&
                        (
                            <StyledImageColumn>
                                {
                                    overviewImage.map((image) => {
                                        const alt = image.alt ? image.alt : image.title;
                                        return (
                                            <ImageContainer key={image.id}>
                                                <ImageImgix
                                                    htmlAttributes={{ alt: alt }}
                                                    id={image.id}
                                                    src={image.url}
                                                    sizes={imageParams.sizes}
                                                    imgixParams={imageParams.params}
                                                    width={image.width}
                                                    height={image.height}
                                                />
                                            </ImageContainer>
                                        )
                                    })
                                }
                            </StyledImageColumn>
                        )
                    }
                    <StyledTextColumn>
                        <StyledEventDate>{eventDatum}</StyledEventDate>
                        <StyledTitle>
                            {title}
                        </StyledTitle>
                        <StyledDescription>
                            {overviewDescription}
                            <StyledLink to={`/${uri}`}>
                                {language === "de" && "Mehr Infos… "}
                                {language === "fr" && "Plus d'infos… "}
                                {language === "it" && "Per saperne di più… "}
                                {language === "en" && "More info… "}
                            </StyledLink>
                        </StyledDescription>
                        {ticketlink && (
                            <StyledButton>
                                <a href={`${ticketlink}`} target="_blank">
                                    {language === "de" && "Ticket kaufen"}
                                    {language === "fr" && "Acheter un billet"}
                                    {language === "it" && "Acquista il biglietto"}
                                    {language === "en" && "Buy ticket"}
                                </a>
                            </StyledButton>
                        )}
                    </StyledTextColumn>
                </StyledEventCalendarEventInner>

            </Container>
        </StyledEventCalendarEvent >
    )

}
export default EventCalendar;