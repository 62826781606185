import * as React from "react";
import styled from "styled-components";
import Container from "../Container";
import { PageType, ContentBlockCategoriesTeaserBlockType } from "../../types/Types";
import { breakpoints, colors, fonts } from "../../styles/variables";
import ImageImgix from "../ImageImgix";
import { Link } from "gatsby";

const StyledTeaserBlockCategories = styled.div`
    margin: 35px 0;
`;
const TeaserBlockWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    column-gap: 10px;
    row-gap: 10px;
    border-top: solid 2px ${colors.black};
    padding-top: 45px;

    & > * {
        flex: 1 1 30%;
    }

    @media screen and (min-width: ${breakpoints.sm}px) {
    }
`;
const TeaserBlockItem = styled.div`
    position: relative;
    overflow: hidden;
    aspect-ratio: 1;
    min-width: 20ch;
    background-color: ${colors.red};
`
const ImageContainer = styled.div`
    line-height: 0;
    position: absolute;

    img {
        // max-width: 100%;
    }
`
const StyledTitle = styled.h2`
    font: ${fonts.title};
    text-transform: uppercase;
    margin: 0 0 1rem 0;
    color: white;

    a {
        text-decoration: none;
        color: inherit;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        inset: 0 0 0 0;

    }
`

const TeaserBlockCategories: React.FC<ContentBlockCategoriesTeaserBlockType> = ({ title, teaserBlockCategories }) => {
    const imageParams = {
        sizes:
            "(max-width: 576px) 100vw 100vh, (max-width: 768px) 100vw, (max-width: 992px) 100vw, (max-width: 1200px) 100vw", // todo
        params: {
            q: 50,
            ar: 1,
            fit: 'crop'
        },
    };
    return (
        <StyledTeaserBlockCategories>
            <Container>
                <TeaserBlockWrapper>
                    {teaserBlockCategories.length > 0 && teaserBlockCategories.map((page) => (
                        <TeaserBlockItem>
                            {page.overviewImage && page.overviewImage.length > 0 &&
                                (
                                    <>
                                        {
                                            page.overviewImage.map((image) => {
                                                const alt = image.alt ? image.alt : image.title;
                                                return (
                                                    <ImageContainer key={image.id}>
                                                        <ImageImgix
                                                            id={image.id}
                                                            src={image.url}
                                                            htmlAttributes={{ alt: alt }}
                                                            sizes={imageParams.sizes}
                                                            imgixParams={imageParams.params}
                                                            width={image.width}
                                                            height={image.height}
                                                        />
                                                    </ImageContainer>
                                                )
                                            })
                                        }
                                    </>
                                )
                            }
                            <StyledTitle><Link to={`/${page.uri}`}>{page.title}</Link></StyledTitle>
                        </TeaserBlockItem>
                    ))}
                </TeaserBlockWrapper>
            </Container>
        </StyledTeaserBlockCategories>
    )

}
export default TeaserBlockCategories;