import * as React from "react";
import styled from "styled-components";
import { ContentBlockMenuCardBlockEntryBlockType } from "../../types/Types";
import { breakpoints, fonts } from "../../styles/variables";

const StyledContentBlockMenuCardBlockEntryBlock = styled.div`
    margin: 15px 0 10px 0;
`
const AnzahlPersonen = styled.h2`
    font: ${fonts.headline};
    text-align: center;
    margin: 0 0 15px 0;
`
const MenuCardBlockContainer = styled.div`
    display: flex;
    justify-content: space-between;
    position: relative;
`
const TextContainer = styled.div`
    position: relative;
    flex-grow: 1;
`
const PriceContainer = styled.div`
    flex-grow: auto;
    display: flex;
	align-items: flex-end;
`
const TrailingDots = styled.div`
    position: absolute;
    width: 100%;
    height: 1em;
    overflow: hidden;
    bottom: 0.15em;
    font: ${fonts.text};
    line-height: 1;
    z-index: 1;

    &:after {
        content:
            " . . . . . . . . . . . . . . . . . . . ."
            " . . . . . . . . . . . . . . . . . . . ."
            " . . . . . . . . . . . . . . . . . . . ."
            " . . . . . . . . . . . . . . . . . . . ."
            " . . . . . . . . . . . . . . . . . . . ."
            " . . . . . . . . . . . . . . . . . . . ."
            " . . . . . . . . . . . . . . . . . . . ."
        }
    }
`
const TextContainerInner = styled.div`
    display: block;
    line-height: 1.25;
    margin: 0;
    max-width: 700px;
`
const MenuEntryLabelOuter = styled.div`
    display: block;

    @media screen and (min-width: ${breakpoints.sm}px) {
        display: flex;
        align-items: stretch;
    }
`
const MenuEntryLabel = styled.span`
    font: ${fonts.textitalic};
    line-height: 1.25;
    padding: 0 25px 0 15px;
    background-color: white;
    z-index: 2;
    position: relative;
`
const MenuEntryTextOuter = styled.div`
`
const MenuEntryText = styled.span`
    font: ${fonts.text};
    line-height: 1.25;
    display: inline;
    margin-bottom: 0;
    background-color: white;
    z-index: 2;
    position: relative;
    padding-right: 5px;
`
const PriceContainerInner = styled.span`
    position: absolute;
    bottom: 0;
    background-color: white;
    z-index: 2;
    position: relative;
`
const MenuEntryPrice = styled.p`
    font: ${fonts.infotext};
    line-height: 1.25;
    margin: 0;
    white-space: nowrap;
`
const AnzahlPersonenPreis = styled.span`
    font: ${fonts.text};
    line-height: 1.25;
`

const ContentBlockMenuCardBlockEntryBlock: React.FC<ContentBlockMenuCardBlockEntryBlockType> = ({ anzahlPersonen, menuEntryText, menuEntryPrice, menuEntryLabel }) => {
    return (
        <StyledContentBlockMenuCardBlockEntryBlock>
            {anzahlPersonen && anzahlPersonen > 1 && (<AnzahlPersonen>ab {anzahlPersonen} Personen</AnzahlPersonen>)}
            <MenuCardBlockContainer>
                <TextContainer>
                    <TextContainerInner>
                        {menuEntryLabel.length ? (
                            <MenuEntryLabelOuter>
                                <MenuEntryLabel>{menuEntryLabel}</MenuEntryLabel>
                                <MenuEntryTextOuter><MenuEntryText>{menuEntryText}</MenuEntryText></MenuEntryTextOuter>
                            </MenuEntryLabelOuter>
                        ) : (<MenuEntryText>{menuEntryText}</MenuEntryText>)}
                        <TrailingDots />
                    </TextContainerInner>
                </TextContainer>
                <PriceContainer>
                    <PriceContainerInner>
                        <MenuEntryPrice>
                            <AnzahlPersonenPreis>{anzahlPersonen && anzahlPersonen > 1 && "p.P."}</AnzahlPersonenPreis> {menuEntryPrice}
                        </MenuEntryPrice>
                    </PriceContainerInner>
                </PriceContainer>
            </MenuCardBlockContainer>
        </StyledContentBlockMenuCardBlockEntryBlock>
    )

}
export default ContentBlockMenuCardBlockEntryBlock;