import * as React from "react";
import styled from "styled-components";
import Container from "../Container";
import { ContentBlockTitleBlockType } from "../../types/Types";
import { fonts } from "../../styles/variables";

const StyledContentBlockTitleBlock = styled.div`
    margin: 35px 0;
`;
const Headline = styled.h1`
    font: ${fonts.headline};
    text-align: center;
    margin: 0;
    text-transform: uppercase;
`

const ContentBlockTitleBlock: React.FC<ContentBlockTitleBlockType> = ({ titleBlockTitle }) => {

    return (
        <StyledContentBlockTitleBlock>
            <Container>
                <Headline>{titleBlockTitle}</Headline>
            </Container>
        </StyledContentBlockTitleBlock>
    )

}
export default ContentBlockTitleBlock;