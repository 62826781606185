import * as React from "react";
import styled from "styled-components";
import Container from "../Container";
import { ContentBlockImageBlockType } from "../../types/Types";
import ImageImgix from "../ImageImgix";

const StyledContentBlockImageBlock = styled.div``;

const ImagesOuterContainer = styled.div`
display: flex;
line-height: 0;
`
const ImageContainer = styled.div` 
line-height: 0;
width: 100%;
`

const ContentBlockImageBlock: React.FC<ContentBlockImageBlockType> = ({ imageBlockImage }) => {

    return (
        <StyledContentBlockImageBlock>
            <Container>
                <ImagesOuterContainer>
                    {imageBlockImage && imageBlockImage.length > 0 && imageBlockImage.map((image) => {
                        const alt = image.alt ? image.alt : image.title;
                        const imageParams = {
                            sizes:
                                `(max-width: 576px) calc(100vw - 34px), 
                                (max-width: 767px) 733px,
                                (max-width: 768px) calc(100vw - 112px),
                                (max-width: 992px) calc(100vw - 331px),
                                    (max-width: 1300px) calc(950px / ${imageBlockImage.length}),
                                    calc(950px / ${imageBlockImage.length})`,
                            params: { q: 50, auto: "compress,format" },
                        };
                        return (
                            <ImageContainer key={image.id}>
                                <ImageImgix
                                    htmlAttributes={{ alt: alt }}
                                    id={image.id}
                                    src={image.url}
                                    sizes={imageParams.sizes}
                                    imgixParams={imageParams.params}
                                    className={`image-${image.id}`}
                                    width={image.width}
                                    height={image.height}
                                />
                            </ImageContainer>
                        )
                    })}
                </ImagesOuterContainer>
            </Container>
        </StyledContentBlockImageBlock>
    );
};

export default ContentBlockImageBlock; 