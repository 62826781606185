import * as React from "react";
import styled from "styled-components";
import Container from "../Container";
import { ContentBlockTextBlockType } from "../../types/Types";
import { fonts } from "../../styles/variables";

const StyledContentBlockTextBlock = styled.div`
    margin: 35px 0;
`;
const StyledTextContainer = styled.div`
    * {
        font: ${fonts.text};
    }

    p {
        font: ${fonts.text};
    }

    strong {
        font-weight: 700;
    }
    
    ul, ol, li {
        font: ${fonts.text};
    }

    ul, ol, li {
        font: ${fonts.text};
    }

    ul {
        li {
            list-style-type: "– ";
        }
    }

    a {
        color: inherit;
    }
    em {
    font: ${fonts.textitalic};
    }
`;

const ContentBlockTextBlock: React.FC<ContentBlockTextBlockType> = ({ textBlockText }) => {
    return (
        <StyledContentBlockTextBlock>
            <Container>
                <StyledTextContainer dangerouslySetInnerHTML={{ __html: textBlockText }} />
            </Container>
        </StyledContentBlockTextBlock>
    );
};

export default ContentBlockTextBlock; 