import * as React from "react";
import styled from "styled-components";
import Container from "../Container";
import { ContentBlockCatBlockRoomsType, PageType } from "../../types/Types";
import { breakpoints, colors, fonts } from "../../styles/variables";
import ImageImgix from "../ImageImgix";

const StyledContentBlockCatBlockRooms = styled.div`
    margin: 35px 0;
`;
const Headline = styled.h1`
    font: ${fonts.headline};
    text-align: center;
    margin: 0;
    text-transform: uppercase;
`
const BackgroundColorContainer = styled.div`
`
const RoomContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    column-gap: 20px;
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-top: solid 2px ${colors.black};
    background-color: white;

    &:last-of-type {
        padding-bottom: 20px;
    }

    @media screen and (min-width: ${breakpoints.sm}px) {
        flex-wrap: nowrap;
    }

    & > * {
        flex: 1 1 100%;
    }
`
const ImageContainer = styled.div`
    img {
        // max-width: 100%;
    }
`
const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 15px 20px 0 0;
`
const StyledTitle = styled.h2`
    font: ${fonts.title};
    margin: 0;
    text-transform: uppercase;
`
const StyledDescription = styled.p`
    font: ${fonts.text};
    margin-top: 0;
`;
const StyledButton = styled.div`
    position: relative;
    text-align: right;
    z-index: 2;

    a {
        border: solid 1px black;
        padding: 0.5em 1em;
        color: black;
        text-decoration: none;
        display: inline-block;
    }
`
const ContentBlockCatBlockRooms: React.FC<ContentBlockCatBlockRoomsType> = ({ title, overviewButtonText, overviewButtonLink, overviewPages, language }) => {
    const imageParams = {
        sizes:
            "(max-width: 576px) 100vw 100vh, (max-width: 768px) 100vw, (max-width: 992px) 100vw, (max-width: 1200px) 100vw", // todo
        params: { q: 50 },
    }
    function translateMore() {
        switch(language) {
            case "de":
                return "Mehr Infos…";
            case "fr":
                return "Plus d'infos…";
            case "it":
                return "Per saperne di più…";
            default:
                return "More info…";
        }
    }
    return (
        <StyledContentBlockCatBlockRooms>
            <Container>
                <BackgroundColorContainer>
                <Headline>{title}</Headline>
                    {overviewPages.length > 0 && overviewPages.map((page) => (
                        <RoomContainer key={page.id}>
                            {page.overviewImage && page.overviewImage.length > 0 &&
                                (
                                    <>
                                        {
                                            page.overviewImage.map((image) => {
                                                const alt = image.alt ? image.alt : image.title;
                                                return (
                                                    <ImageContainer key={image.id}>
                                                        <ImageImgix
                                                            id={image.id}
                                                            src={image.url}
                                                            htmlAttributes={{ alt: alt }}
                                                            sizes={imageParams.sizes}
                                                            imgixParams={imageParams.params}
                                                            width={image.width}
                                                            height={image.height}
                                                        />
                                                    </ImageContainer>
                                                )
                                            })
                                        }
                                    </>
                                )
                            }

                            <ContentContainer>
                                <div>
                                    <StyledTitle>{page.title}</StyledTitle>
                                    <StyledDescription>{page.overviewDescription}</StyledDescription>
                                </div>
                                <StyledButton>
                                    <a href={overviewButtonLink ? overviewButtonLink : page.url } target={overviewButtonLink ? "_blank" : "_self" }>
                                        { overviewButtonText ? overviewButtonText : translateMore() }
                                    </a>
                                </StyledButton>
                            </ContentContainer>
                        </RoomContainer>

                    ))}
                </BackgroundColorContainer>
            </Container>
        </StyledContentBlockCatBlockRooms>
    )

}
export default ContentBlockCatBlockRooms;