import * as React from "react";
import styled from "styled-components";
import Container from "../Container";
import { ContentBlockNewsletterBlockType } from "../../types/Types";
import { fonts } from "../../styles/variables";

const StyledContentBlockNewsletterBlock = styled.div`
    margin: 35px 0;
`;

const StyledIframe = styled.iframe`
    margin: 0;
    padding: 0;
    border: none;
    min-height: 250px;
`;

const StyledNewsletterBlockText = styled.div`
* {
    font: ${fonts.text};
}

p {
    font: ${fonts.text};
}

strong {
    font-weight: 700;
}

ul, ol, li {
    font: ${fonts.text};
}

ul, ol, li {
    font: ${fonts.text};
}

ul {
    li {
        list-style-type: "– ";
    }
}

a {
    color: inherit;
}
em {
font: ${fonts.textitalic};
}
`;

const ContentBlockNewsletterBlock: React.FC<ContentBlockNewsletterBlockType> = ({ newsletterBlockText }) => {

    return (
        <StyledContentBlockNewsletterBlock>
            <Container>
                <StyledNewsletterBlockText dangerouslySetInnerHTML={{ __html: newsletterBlockText }} />
                <>
                    <StyledIframe data-w-type="embedded" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://0mqui.mjt.lu/wgt/0mqui/xo99/form?c=14071a47" width="100%" />
                    <script type="text/javascript" src="https://app.mailjet.com/pas-nc-embedded-v1.js"></script>
                </>
            </Container>
        </StyledContentBlockNewsletterBlock>
    )

}
export default ContentBlockNewsletterBlock;