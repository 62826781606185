import * as React from "react";
import styled from "styled-components";
import { ContentBlockMenuCardBlockTitleBlockType } from "../../types/Types";
import { fonts } from "../../styles/variables";

const StyledContentBlockMenuCardBlockTitleBlock = styled.div`
    margin: 35px 0 15px 0;
`
const StyledTitle = styled.h2`
    font: ${fonts.headline};
    text-align: center;
    text-transform: uppercase;
`

const ContentBlockMenuCardBlockTitleBlock: React.FC<ContentBlockMenuCardBlockTitleBlockType> = ({ menuEntryTitle }) => {
    return (
        <StyledContentBlockMenuCardBlockTitleBlock>
            <StyledTitle>{menuEntryTitle}</StyledTitle>
        </StyledContentBlockMenuCardBlockTitleBlock>
    )
}
export default ContentBlockMenuCardBlockTitleBlock;