import * as React from "react";
import styled from "styled-components";
import { ContentBlockMenuCardBlockPageBreakBlockType } from "../../types/Types";

const StyledContentBlockMenuCardBlockPageBreakBlock = styled.div`
    @media print {
        &.page-break {
            page-break-after: always;
        }
    }
`

const ContentBlockMenuCardBlockPageBreakBlock: React.FC<ContentBlockMenuCardBlockPageBreakBlockType> = ({ menuEntryPageBreak }) => {
    return (
        <StyledContentBlockMenuCardBlockPageBreakBlock className={menuEntryPageBreak && "page-break"} />
    )
}
export default ContentBlockMenuCardBlockPageBreakBlock;