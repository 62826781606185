import * as React from "react";
import styled from "styled-components";
import Container from "../Container";
import { ContentBlockTwoColumnsBlockType } from "../../types/Types";
import Imgix from "react-imgix";
import { breakpoints, fonts } from "../../styles/variables";
import ImageImgix from "../ImageImgix";

const StyledContentBlockTwoColumnsBlock = styled.div`
    margin: 35px 0;
`;

const TwoColumns = styled.div` 

    @media screen and (min-width: ${breakpoints.lg}px) {
        display: flex;
        column-gap: 45px;
        flex-direction: row;

        &.row-reverse {
            flex-direction: row-reverse;
        }
    }

`
const Column = styled.div` 
    @media screen and (min-width: ${breakpoints.lg}px) {
        width: 50%;
    }
`
const ImageContainer = styled.div` 
`

const StyledTextContainer = styled.div`
    * {
        font: ${fonts.text};
    }

    p {
        font: ${fonts.text};
    }

    strong {
        font-weight: 700;
    }
    
    ul, ol, li {
        font: ${fonts.text};
    }

    ul {
        li {
            list-style-type: "– ";
        }
    }

    a {
        color: inherit;
    }
`;

const ContentBlockTwoColumnsBlock: React.FC<ContentBlockTwoColumnsBlockType> = ({twoColumnsImage, twoColumnsText, twoColumnsImgPos}) => {
    const imageParams = {
        sizes:
            `(max-width: 576px) 100vw calc(100vw - 34px), 
                (max-width: 768px) 733px,
                (max-width: 992px) 320px,
                (max-width: 1200px) 450px`,
        params: { q: 50, auto: "compress,format" },
    };

    return (
        <StyledContentBlockTwoColumnsBlock>
            <Container>
                <TwoColumns className={twoColumnsImgPos ? "row-reverse" : "" }>
                    <Column>
                            {twoColumnsImage && twoColumnsImage.length > 0 && twoColumnsImage.map((image) => {
                            const alt = image.alt ? image.alt : image.title;
                            
                            return (
                                <ImageContainer key={image.id}>
                                    <ImageImgix
                                        htmlAttributes={{ alt: alt }}
                                        id={image.id}
                                        src={image.url}
                                        sizes={imageParams.sizes}
                                        imgixParams={imageParams.params}
                                        width={image.width}
                                        height={image.height}
                                    />
                                </ImageContainer>
                                )
                            })}
                    </Column>
                    <Column>
                        <StyledTextContainer dangerouslySetInnerHTML={{ __html: twoColumnsText }} />
                    </Column>
                </TwoColumns>
            </Container>
        </StyledContentBlockTwoColumnsBlock>
    );
};

export default ContentBlockTwoColumnsBlock; 