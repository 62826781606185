import * as React from "react";
import styled from "styled-components";
import Container from "../Container";
import { ContentBlockMenuCardBlockEntryBlockType, ContentBlockMenuCardBlockPageBreakBlockType, ContentBlockMenuCardBlockTitleBlockType, ContentBlockMenuCardBlockType } from "../../types/Types";
import ContentBlockMenuCardBlockEntryBlock from "./ContentBlockMenuCardBlockEntryBlock";
import ContentBlockMenuCardBlockTitleBlock from "./ContentBlockMenuCardBlockTitleBlock";
import { fonts } from "../../styles/variables";
import ContentBlockMenuCardBlockPageBreakBlock from "./ContentBlockMenuCardBlockPageBreakBlock";

const StyledContentBlockMenuCardBlock = styled.div`
    margin: 35px 0 35px 0;


`;
const StyledMenuCardContainer = styled.div`
    background-color: white;
    padding: 0 25px 15px 25px;
    border-top: 2px solid black;
    border-bottom: 1px solid black;

    @media print {
        border: none;

        @page {
            size: 148.5mm 210mm;
            margin: 0;
        }
    }
`;
const StyledTextContainer = styled.div`
    * {
        font: ${fonts.infotext};

    }

    p {
        font: ${fonts.text};
    }

    strong {
        font-weight: 700;
    }
    
`;
const StyledMenuCardZusatz = styled.div`
    text-align: center;
    padding-top: 10px;

    * {
        font: ${fonts.infotext};
    }

    p {
        font: ${fonts.textsmall};
    }
`;

const ContentBlockMenuCardBlock: React.FC<ContentBlockMenuCardBlockType> = ({ menuCardDescription, menuCardZusatz, menuCardMenu }) => {
    return (
        <StyledContentBlockMenuCardBlock>
            <Container className={"no-space"}>
                <StyledTextContainer dangerouslySetInnerHTML={{ __html: menuCardDescription }}></StyledTextContainer>
                <StyledMenuCardContainer>
                    {menuCardMenu && menuCardMenu.length > 0 && menuCardMenu[0].menuEntry.map((menuCardBlockMenuCardItem: (ContentBlockMenuCardBlockEntryBlockType | ContentBlockMenuCardBlockTitleBlockType)) => {
                        switch (menuCardBlockMenuCardItem.typeHandle) {
                            case "menuEntryBlock": {
                                return (
                                    <ContentBlockMenuCardBlockEntryBlock
                                        {...(menuCardBlockMenuCardItem as ContentBlockMenuCardBlockEntryBlockType)}
                                        key={menuCardBlockMenuCardItem.id} />
                                )
                            };
                            case "menuEntryTitleBlock": {
                                return (
                                    <ContentBlockMenuCardBlockTitleBlock
                                        {...(menuCardBlockMenuCardItem as ContentBlockMenuCardBlockTitleBlockType)}
                                        key={menuCardBlockMenuCardItem.id} />
                                )
                            };
                            case "menuEntryPageBreak": {
                                return (
                                    <ContentBlockMenuCardBlockPageBreakBlock
                                        {...(menuCardBlockMenuCardItem as ContentBlockMenuCardBlockPageBreakBlockType)}
                                        key={menuCardBlockMenuCardItem.id} />
                                )
                            };
                            default: ""
                        }
                    })}
                </StyledMenuCardContainer>
                <StyledMenuCardZusatz dangerouslySetInnerHTML={{ __html: menuCardZusatz }}></StyledMenuCardZusatz>
            </Container>
        </StyledContentBlockMenuCardBlock>
    )

}
export default ContentBlockMenuCardBlock;